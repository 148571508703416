<template>
  <div class="container">
    <!-- If you want to hide survey, comment the lines below -->
    <h2>Chaplehay Area Parking Survey</h2>
    <survey :survey="survey"></survey>
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
import parkingSurvey from "./surveyQuestions.js";
import "bootstrap/dist/css/bootstrap.css";
var Survey = SurveyVue.Survey;
Survey.cssType = "bootstrap";

import * as widgets from "surveyjs-widgets";

import { init as customWidget } from "./components/customwidget";

// widgets.icheck(SurveyVue);
widgets.select2(SurveyVue);
widgets.inputmask(SurveyVue);
widgets.jquerybarrating(SurveyVue);
widgets.jqueryuidatepicker(SurveyVue);
widgets.nouislider(SurveyVue);
widgets.select2tagbox(SurveyVue);
widgets.sortablejs(SurveyVue);
widgets.ckeditor(SurveyVue);
widgets.autocomplete(SurveyVue);
widgets.bootstrapslider(SurveyVue);
customWidget(SurveyVue);

SurveyVue.Serializer.addProperty("question", "tag:number");

export default {
  components: {
    Survey,
  },
  data() {

    window.survey = new SurveyVue.Model(parkingSurvey);

    window.survey.onComplete.add(function (sender) {

      const starts = window.localStorage.getItem("starts");
      const submits = parseInt(window.localStorage.getItem("sumbits")) + 1;
      window.localStorage.setItem("submits", submits);

      const response = sender.data;
      const meta = {
        "deviceKey":  window.localStorage.getItem("quizKey"),
        "timeTaken": ((new Date() ) - window.startTime)/1000,
        "counts": [starts,submits]
      };
      
      response.meta = meta;

      console.log("submitting: ", JSON.stringify(response, null, 3));


      fetch("/post.pl", {
        method: "POST",
        headers: {'Content-Type': 'application/json'}, 
        body: JSON.stringify(response)
      }).then(res => {
        console.log("Request complete! response:", res);
      });

    });

    window.survey.onCurrentPageChanged.add(function (){

      const start = window.startTime;
      const progress = window.survey.getProgress();
      const startProgress = 15;

      if (! start && progress > startProgress){
        // we are starting
        window.startTime = new Date();
        console.log("quiz started: ",  window.startTime.toString() );

        //cookie preference is set

        const myKey = window.localStorage.getItem("quizKey");

        if (!myKey){
          window.localStorage.setItem("quizKey", window.CryptoJS.MD5(window.startTime.toString()).toString() );
          window.localStorage.setItem("starts", 1);
          window.localStorage.setItem("submits",0);
        } else {
          const starts = parseInt(window.localStorage.getItem("starts")) +1;
          window.localStorage.setItem("starts", starts);
        }
      }

      
    });

    return {
      survey: window.survey,
    };
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 2em;
}
</style>
