export default {
    title: "Parking Survey",
    showProgressBar: "bottom",
    goNextPageAutomatic: true,
    showNavigationButtons: true,
    pages: [
      {
        questions: [
          {
            type: "html",
            name: "preamble",
            html: `
            This is an informal survey about parking in the Chaplehay area for Cllr Luke Wakeling.  The purpose of this survey is to inform about parking concerns, and if residents would support a permit scheme.  If there is strong support for a change to the status-quo,  I will ask Weymouth Town Council to make the request to Dorset Council.  Dorset Council would then decide how to proceed, that may include a formal survey,  taking the suggestion forward, or refusing the request.<br><br>
            I am looking for one response per household.  Please try to answer on behalf of your whole household.<br><br>
            Any personal information will not be shared with any other parties. Answers provided will be anonymised, and any personal data collected will be destroyed after the survey results have been compiled.              This site uses cookies, by proceeding you agree to storing cookies on your computer for functionality of this survey.<br><br>
            Please tap "Next" to continue
            `,
          },
        ],
      },
      {
        questions: [
          {
            type: "html",
            name: "map",
            html: `<img src='/thumbnail_image001.png' />`,
          },
          {
            type: "checkbox",
            name: "inArea",
            title: "Do you live in the area shown on the map?",
            isRequired: true,
            //"hasNone": true,
            colCount: 1,
            choices: [
              { value: "in", text: "I live in the area shown" },
              {
                value: "work",
                text: "I work or run a business in the area shown",
              },
              {
                value: "care",
                text: "I provide care to someone in the area shown",
              },
              { value: "near", text: "I live on a neighbouring street" },
              {
                value: "out",
                text: "I do not live, work or provide care in or near the area shown",
              },
            ],
          },
          {
            visibleIf: "{inArea} contains 'out'",
            type: "text",
            name: "outWhy",
            title: "Why are you completing this survey?",
            isRequired: true,
          },
        ],
      },
      {
        visibleIf: "{inArea} contains 'in' or {inArea} contains 'near'",
        questions: [
          {
            type: "text",
            // "inputType":"number",
            name: "postcode",
            title: "What is your postcode?",
            description:
              "We need this to check that this survey has reached as many households as possible.",
            isRequired: true,
          },
          {
            type: "text",
            // "inputType":"number",
            name: "address",
            title: "What is the first line of your address?",

            isRequired: true,
          },
          {
            visibleIf: "{inArea} contains 'in'",
            type: "text",
            inputType: "number",
            name: "carsCount",
            title: "How many cars/vans does you household have?",
            description:
              "Include any vehicles that are normally parked at your address when they are not being used, including work vehicles.",
            isRequired: true,
          },
          {
            visibleIf: "{inArea} contains 'in'",
            type: "text",
            inputType: "number",
            name: "spacesCount",
            title:
              "How many off-street parking spaces does you household have?",
            description:
              "Include any driveway/garage spaces that are private to your household.  Even if they are not used for parking (eg. garage used for storage)  Do not include shared off-street parking spaces.",
            isRequired: true,
          },
        ],
      },
      {
        questions: [
          {
            type: "matrix",
            name: "difficulty",
            title:
              "Please indicate how easily you are usually able to park within a **2 minute** walk of your address at the following times:",
            columns: [
              {
                value: 1,
                text: "Normally Okay",
              },

              {
                value: 2,
                text: "Hit and Miss ",
              },
              {
                value: 3,
                text: "Difficult",
              },
              {
                value: 4,
                text: "Very Difficult",
              },
            ],
            rows: [
              {
                value: "daytime",
                text: "Weekday Daytime",
              },
              {
                value: "evenings",
                text: "Weekday Evenings",
              },
              {
                value: "weekends",
                text: "Weekends",
              },
            ],
          },
          {
            type: "html",
            name: "description2",
            html: "Please consider a general view for each time period, if you do not regularly park at a particular time of day, please leave it blank.",
          },

        ],
      },
      {
        questions: [
          {
            type: "radiogroup",
            name: "disability",
            title: "Any member of my household has a disability",
            choices: ["Yes", "No", "Prefer not to say"],
          },
          {
            type: "radiogroup",
            name: "care",
            title: "Any member of my household receives care",
            choices: ["Yes", "No", "Prefer not to say"],
          },

        ],
      },

      {
        questions: [
          {
            type: "html",
            name: "description",
            html: `
            If Dorset consider Parking Permits,  each residential household would be able to request permits for an annual administration charge of £70 (2021 prices) per vehicle.
            Households would also be able to purchase visitor permits for use if you have visitors or tradespeople needing to park (£16 per 20 days - 2021 prices).<br>
            There are just under 700 addresses in the proposed zone (approx. 600 residential, 60 holiday lets, 20 businesses) There are fewer than 350 on-street parking spaces in the proposed zone.  A permit scheme will not create any new spaces.  Each residential property would be able to purchase permits for any vehicles registered at an address in the zone, and there would be more permits issued than parking spaces.  A permit does not guarantee a parking space.<br>
            If you or your visitors park with out a valid permit, you risk a parking ticket.<br>
            It is not possible to "trial" a parking scheme, so if one is adopted it would be permanent.
            `,
          },
          {
            type: "matrix",
            name: "supportForPermits",
            title:
              "Please indicate if you agree or disagree with the following statements",
            columns: [
              {
                value: 1,
                text: "Strongly Disagree",
              },

              {
                value: 2,
                text: "Slightly Disagree",
              },
              {
                value: 3,
                text: "Slightly Agree",
              },
              {
                value: 4,
                text: "Strongly Agree",
              },
            ],
            rows: [
              {
                value: "permits247",
                text: "I would support Residents Parking permits 24 hours a day, 7 days a week",
              },
              {
                value: "permits95",
                text: "I would support Residents Parking Permits 8am-6pm Monday-Saturday",
              },
              {
                value: "permitsNight",
                text: "I would support Residents Parking Permits 6pm-8am, 7 days a week",
              },
            ],
          },
        ],
      },
      {
        questions: [
          {
            type: "comment",
            name: "comment",
            title: "Would you like to add any comments:",
          },
        ],
      },
      {
        questions: [
            
          {
            type: "text",
            inputType: "email",
            name: "email",
            title:
              'Thank you for taking our survey. If you would like to be notified of the results, please enter your email address, then press the "Submit" button.',
          },
        ],
      },
    ],
  };